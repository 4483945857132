<template>
  <base-layout>
    <the-error-dialog
      :errorMessage="errorMessage"
      @handle-error="handleError"
    />
    <q-linear-progress v-if="isLoading" indeterminate />
    <div>
      <q-card>
        <q-card-section>
          <b
            ><div class="text-right">{{ getStatusString(bill.Status) }}</div></b
          >
          <logo />
          <div class="text-h5">Счёт № {{ bill.BillNumber }}</div>
          <div class="text-subtitle2">{{ dateConverter(bill.BillDate) }}</div>
          <div class="text-subtitle2">{{ bill.Description }}</div>
        </q-card-section>

        <q-markup-table class="scroll">
          <thead>
            <tr>
              <th class="text-left">Наименование</th>
              <th class="text-right">Кол-во</th>
              <th class="text-right">Цена</th>
            </tr>
          </thead>
          <tbody>
            <bill-item
              v-for="item in bill.Items"
              :key="item.ItemId"
              :billItem="item"
            ></bill-item>
          </tbody>
        </q-markup-table>
        <the-price-footer
          :originalPrice="bill.OriginalTotalPrice"
          :totalPrice="bill.TotalPrice"
        ></the-price-footer>
        <div class="text-right q-pa-sm text-subtitle2">
          Плательщик: {{ bill.BuyerName }}
        </div>
        <div class="row">
          <div
            class="q-ma-sm col"
            v-if="bill.IsCancelable === true && payBillCanExecute() === true"
          >
            <q-btn
              color="red"
              class="full-width"
              label="Изменить"
              @click="cancelBill"
            />
          </div>
          <div class="q-ma-sm col" v-if="payBillCanExecute() === true">
            <q-btn
              color="primary"
              class="full-width"
              label="Оплатить"
              @click="payBill"
            />
          </div>
        </div>
      </q-card>
    </div>
  </base-layout>
</template>

<script>
import BillItem from "./BillItem";
import axios from "axios";
import { isMobile } from "mobile-device-detect";

export default {
  props: ["bill", "guid"],
  components: {
    BillItem,
  },
  data() {
    return {
      errorMessage: "",
      isLoading: false,
    };
  },
  methods: {
    getStatusString() {
      if (!this.visibleForUserBillStatuses.includes(this.bill.Status))
        return null;
      return "статус: " + this.billStatus[this.bill.Status];
    },

    payBillCanExecute() {
      return this.availableForPaymentBillStatuses.includes(this.bill.Status);
    },

    payBill() {
      this.isLoading = true;

      const data = JSON.stringify({
        billUid: this.guid,
        pageView: isMobile ? 0 : 1,
      });

      axios
        .post(process.env.VUE_APP_API_GETPAYMENTURL, data)
        .then((response) => {
          if (!response.data || !response.data.PaymentUrl) {
            this.showError((response.data && response.data.ErrorMessage) 
              ? response.data.ErrorMessage
              : "Ошибка при попытке оплаты счёта. Попробуйте повторить позже");
          } else if (response.data.ErrorMessage) {
            this.showError(response.data.ErrorMessage);
          } else {
            window.location.href = response.data.PaymentUrl;
          }
        })
        .catch((error) => {
          console.log("Request error", error);
          this.showError(
            "Ошибка при попытке оплаты счёта. Попробуйте повторить позже"
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    cancelBill() {
      this.isLoading = true;

      const data = JSON.stringify({
        billId: this.guid,
      });

      axios
        .post(process.env.VUE_APP_API_CANCELBILL, data)
        .then((response) => {
          if (response.status != 200) {
            this.showError(
              "Ошибка при попытке отмены счёта. Попробуйте повторить позже"
            );
          } else if (response.data.ErrorMessage) {
            this.showError(response.data.ErrorMessage);
          } else {
            this.$router.push({
              name: "cartContentInfo",
              params: { guid: this.bill.BuyerId },
            });
          }
        })
        .catch((error) => {
          console.log("Request error", error);
          this.showError(
            "Ошибка при попытке отмены счёта. Попробуйте повторить позже"
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showError(msg) {
      this.errorMessage = msg;
    },
    handleError() {
      this.errorMessage = null;
    },
  },
};
</script>

<style lang="sass" scoped>
.inline
  display: inline-block
.scroll
  overflow: hidden
</style>